import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@tencent/tdesign-react';
import { checkTxAddr, getEllipsis } from '../../../utils/utils';

export default function LinkFrom({ from = '' }: { from: string }) {
  const isTxAddr = checkTxAddr(from);
  const sFrom = getEllipsis(from, 20);
  return (
    <>
      {isTxAddr ? (
        <Link to={`/addrs/${from}`} target="_blank">
          <Tooltip content={from} placement="top" showArrow destroyOnClose>
            {sFrom}
          </Tooltip>
        </Link>
      ) : from.length > 20 ? (
        <Tooltip content={from} placement="top" showArrow destroyOnClose>
          {sFrom}
        </Tooltip>
      ) : (
        <>{sFrom}</>
      )}
    </>
  );
}
