import React, { useState } from 'react';
import { Input } from '@tencent/tdesign-react';
import { Modal, Form, Text } from '@tencent/tea-component';
import FileUpload from './fileUpload';

import './validate.scss';

import LeftIcon from '../../assets/img/left.png';
import OneIcon from '../../assets/img/one.png';
import TwoIcon from '../../assets/img/two.png';
import LoadingIcon from '../../assets/img/loading.png';
import { DOWNLOAD, PASS_ICON, SEARCH_ICON, STEP_ICON, Test_Data, UNPASS_ICON, WHITE_MARK } from './const';
import { fetchBrowserEvQuery } from '../../common/api';

export function ValidatePage() {
  const [hash, setHash] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [successVisble, setSuccessVisble] = useState<boolean>(false);
  const [errorVisble, setErrorVisble] = useState<boolean>(false);
  const [showData, setShowData] = useState<any>(Test_Data);

  // 文件校验
  async function verifyFile() {
    // 非空处理
    if (hash?.trim() === '' || loading) {
      return;
    }

    setLoading(true);

    try {
      const hashResult = await fetchBrowserEvQuery({
        hash: hash.toLocaleLowerCase(),
      });
      const evIdResult = await fetchBrowserEvQuery({
        evId: hash.toLocaleLowerCase(),
      });
      const txIdResult = await fetchBrowserEvQuery({
        txId: hash.toLocaleLowerCase(),
      });
      setLoading(false);
      if (hashResult.data.retCode === 0 || evIdResult.data.retCode === 0 || txIdResult.data.retCode === 0) {
        let showData: any;
        if (hashResult.data.retCode === 0) {
          showData = hashResult.data.data;
        }
        if (evIdResult.data.retCode === 0) {
          showData = evIdResult.data.data;
        }
        if (txIdResult.data.retCode === 0) {
          showData = txIdResult.data.data;
        }
        setShowData(showData);
        setSuccessVisble(true);
      } else {
        setErrorVisble(true);
        setErrorVisble(true);
      }
    } catch (error) {
      setLoading(false);
      setErrorVisble(true);
    }
  }

  return (
    <>
      <div className="clear-gap">
        <div className="tpm-prod-hero__bg-inner"></div>
        <div className="tpm-prod-hero__inner">
          <div className="tpm-prod-hero__content">
            <div className="tpm-prod-hero__header">
              <h1 className="tpm-prod-hero__tit">至信链</h1>
            </div>
            <div className="tpm-prod-hero__desc">
              至信链是腾讯云提供的合规、开放、易用的底层区块链平台。致力于推动数字内容资产化、实体资产数字化并通过区块链技术使得资产的确权及流转成本降低。企业用户与个人开发者既可以快速调用已封装好的链服务，也可以自行编写智能合约并部署，用以服务各类客户。
            </div>
          </div>
        </div>
      </div>
      <div className="back_two_img">
        <img src={LeftIcon} className="bot_right" />

        <section className="evi_inner">
          <section className="verification2">
            <div className="banner_left">
              <img src={OneIcon} />
            </div>

            <div className="input_value">
              <div className="tit-wrap">
                <h2>源文件核验</h2>
              </div>

              <p>
                <img src={WHITE_MARK} />
                当您要核验的文件 ≤ 1GB，直接上传进行核验即可。
                {fileList.length > 0 && <strong>共 {fileList.length} 个文件</strong>}
              </p>

              {/* 文件上传 */}
              <FileUpload
                callback={(files: React.SetStateAction<any[]>) => {
                  setFileList(files);
                }}
              ></FileUpload>
            </div>
          </section>
          {/* hash */}
          <section className="verification">
            <div className="banner_left">
              <img src={TwoIcon} />
            </div>
            <div className="input_value">
              <h2>Hash核验</h2>
              <p>
                <img src={WHITE_MARK} />
                当您要核验核验的文件 ≥ 1GB，请使用Hash核验。
              </p>
              <section className="verification_title_bottom">
                <Input
                  placeholder="请输入存证hash、交易hash、存证ID进行核验"
                  value={hash}
                  onChange={(val: any) => setHash(val)}
                />
                <div
                  className={loading ? ' btn_sbmit btn_sbmit_disabled' : 'btn_sbmit'}
                  onClick={() => {
                    verifyFile();
                  }}
                >
                  {loading ? '核验中...' : '开始核验'}
                </div>
                <img src={SEARCH_ICON} />
              </section>
              <p className="step-p"> *Hash核验步骤 </p>
              <section className="ver_bottom">
                <section className="ver_bottom_step">
                  <span>第一步：下载Hash值生成工具至本地</span>
                  <div className="down">
                    <img src={DOWNLOAD} />
                    <a href="https://evsave.zhixinliancos.cn/%E8%87%B3%E4%BF%A1%E9%93%BE%E8%AF%81%E6%8D%AEHash%E7%94%9F%E6%88%90%E5%B7%A5%E5%85%B7.zip">
                      本地下载
                    </a>
                  </div>
                </section>
                <section className="ver_bottom_img">
                  <img src={STEP_ICON} />
                </section>
                <section className="ver_bottom_step">
                  <span>第二步：在工具中上传电子证据生成Hash值</span>
                </section>
                <section className="ver_bottom_img">
                  <img src={STEP_ICON} />
                </section>
                <section className="ver_bottom_step">
                  <span>第三步：将Hash值复制进【Hash核验】处进行核验</span>
                </section>
              </section>
            </div>
          </section>
        </section>
      </div>
      <SuccessModal
        visible={successVisble}
        onClose={() => {
          setSuccessVisble(false);
        }}
        showData={showData}
      ></SuccessModal>
      <ErrorModal
        visible={errorVisble}
        onClose={() => {
          setErrorVisble(false);
        }}
      ></ErrorModal>
      <LoadingModal
        visible={loading}
        onClose={() => {
          setLoading(false);
        }}
      ></LoadingModal>
    </>
  );
}

// 成功 modal
export function SuccessModal({ visible, onClose, showData }: { visible: boolean; onClose: () => void; showData: any }) {
  return (
    <Modal visible={visible} onClose={onClose} className="bg-modal bg-modal-success">
      <Modal.Body className="error-modal-body">
        <img src={PASS_ICON} className="hash-unpass-icon" />
        <br />
        <Text theme="text">核验通过</Text>
        <br />
        <Text theme="strong">该证据自上链之日起未经篡改</Text>
        <br />
        <hr />
        <Form readonly>
          <Form.Item label="上链时间：">
            <Form.Text>{showData.txTime}</Form.Text>
          </Form.Item>
          <Form.Item label="区块高度：">
            <Form.Text>{showData.blockHeight}</Form.Text>
          </Form.Item>
          <Form.Item label="存证ID：">
            <Form.Text>{JSON.parse(showData.origin).evId}</Form.Text>
          </Form.Item>
          <Form.Item label="存证Hash：">
            <Form.Text>{JSON.parse(showData.origin).evHash}</Form.Text>
          </Form.Item>
          <Form.Item label="交易Hash：">
            <Form.Text>{showData.txId}</Form.Text>
          </Form.Item>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export function ErrorModal({ visible, onClose }: { visible: boolean; onClose: () => void }) {
  return (
    <Modal visible={visible} onClose={onClose} className="bg-modal">
      <Modal.Body className="error-modal-body">
        <img src={UNPASS_ICON} className="hash-unpass-icon" />
        <br />
        <Text theme="text">核验未通过</Text>
        <br />
        <Text theme="danger">该文件未经上链或已被篡改</Text>
        <br />
      </Modal.Body>
    </Modal>
  );
}

// loading
export function LoadingModal({ visible, onClose }: { visible: boolean; onClose: () => void }) {
  return (
    <Modal visible={visible} onClose={onClose}>
      <Modal.Body className="error-modal-body">
        <img src={LoadingIcon} className="hash-loading-icon" />
      </Modal.Body>
    </Modal>
  );
}
