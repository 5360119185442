import React, { useState, useEffect } from 'react';
import { Upload, Form, Modal } from '@tencent/tea-component';
import {
  CLOSE_ICON,
  DROPDOWN,
  ERROR,
  FILE_ICON,
  PASS_ICON,
  RIGHT_ICON,
  SELECT_FILE,
  SUCCESS,
  UNPASS_ICON,
  UPLOAD_ICON,
} from './const';
import { sm3 } from 'sm-crypto';
import { fetchBrowserEvQuery } from '../../common/api';
import { LoadingModal } from './validate';
import { Message } from '@tencent/tdesign-react';

async function calculateHash(file: { arrayBuffer: () => any }) {
  const buffer = await file.arrayBuffer();
  const uint8Array = new Uint8Array(buffer);
  const hash = sm3(uint8Array);
  return hash;
}

const maxSize = 1024 * 1024 * 1024;
export default function FileUpload({ callback }: { callback: (fileList: any[]) => void }) {
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [hashList, setHashList] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>('error');
  const [errorVisble, setErrorVisble] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  async function beforeUpload(file: any) {
    if (file.size >= maxSize) {
      Message.error('文件大于 1GB ，不支持上传', 3000);
      return false;
    }
    const data = await calculateHash(file);
    if (hashList.includes(data)) {
      return false;
    }
    setHashList([...hashList, data]);
    file.status = 'default';
    file.dropdown = false; // 默认不下拉
    file.hash = data;
    setFileList([...fileList, file]);

    return true;
  }

  useEffect(() => {
    callback?.(fileList);
  }, [fileList]);

  // 校验文件
  async function verifyHandle() {
    setLoading(true);
    const result: any[] = [];

    fileList.forEach(async (i, idx) => {
      try {
        const result = await fetchBrowserEvQuery({
          hash: i.hash,
        });
        if (result.data.retCode === 0) {
          i.status = 'success';
          i.showData = result.data.data;
        } else {
          i.status = 'error';
        }
      } catch (error) {
        i.status = 'error';
      }
      setCount(count + 1);
      result.push(i);
      fileList.splice(idx, 1, i);
      setFileList([...fileList]);
    });
  }

  useEffect(() => {
    if (count === fileList.length && fileList.length !== 0) {
      setLoading(false);
      if (fileList.every((i) => i.status === 'success')) {
        setType('success');
      } else {
        setType('error');
      }
      setErrorVisble(true);
      setCount(0);
    }
  }, [count]);

  return (
    <>
      <Upload
        maxSize={maxSize}
        beforeUpload={beforeUpload}
        // multiple
      >
        {({ open }: any) => (
          <div className="upload">
            {/* 没有文件 */}
            {fileList?.length === 0 ? (
              <div
                className="upload_main"
                onClick={() => {
                  open();
                }}
              >
                <Upload.Dragger>
                  <section className="upload_uploadfile">
                    <img src={UPLOAD_ICON} />
                    <p>将文件拖拽到此处或点击选择文件</p>
                  </section>
                </Upload.Dragger>
              </div>
            ) : (
              // 有文件
              <section className="upload_file_main ">
                {fileList?.map((item: any, index: number) => (
                  <div key={index} className={item.status}>
                    <div className="FileSec ">
                      <section className="FileSec_left ">
                        <img src={FILE_ICON} />
                        <p className="file-name">{item.name}</p>
                      </section>

                      {/* 未审核的时候 */}
                      {item.status === 'default' && (
                        <section className="FileSec_right">
                          <section className="FileSec_right_tab">等待核验</section>
                          <img
                            src={CLOSE_ICON}
                            className="close-icon"
                            onClick={() => {
                              fileList.splice(index, 1);
                              setFileList([...fileList]);
                              hashList.splice(index, 1);
                              setHashList([...hashList]);
                            }}
                          />
                        </section>
                      )}

                      {item.status === 'error' && (
                        <section className="FileSec_right unpass-tag">
                          <p>未通过</p>
                          <img
                            src={DROPDOWN}
                            className={item.dropdown ? 'close_icon' : 'open_icon'}
                            onClick={() => {
                              item.dropdown = !item.dropdown;
                              fileList.splice(index, 1, item);
                              setFileList([...fileList]);
                            }}
                          ></img>
                        </section>
                      )}

                      {item.status === 'success' && (
                        <section className="FileSec_right pass-tag">
                          <p>审核通过</p>
                          <img
                            src={DROPDOWN}
                            className={item.dropdown ? 'close_icon' : 'open_icon'}
                            onClick={() => {
                              item.dropdown = !item.dropdown;
                              fileList.splice(index, 1, item);
                              setFileList([...fileList]);
                            }}
                          ></img>
                        </section>
                      )}
                    </div>
                    {item.status !== 'default' && item.dropdown && (
                      <>
                        <section className="main">
                          <section className="main_title">
                            <img src={item.status === 'error' ? ERROR : SUCCESS} className="successTips" />

                            <strong>{item.status === 'error' ? '核验未通过' : '核验已通过'}</strong>
                            <span>
                              {item.status === 'error' ? '该文件未经上链或已被篡改' : '该证据自上链之日起未经篡改'}
                            </span>
                          </section>
                        </section>
                        {item.status === 'success' && (
                          <>
                            <Form readonly className="success-data">
                              <Form.Item label="上链时间：">
                                <Form.Text>{item.showData.txTime}</Form.Text>
                              </Form.Item>
                              <Form.Item label="区块高度：">
                                <Form.Text>{item.showData.blockHeight}</Form.Text>
                              </Form.Item>
                              <Form.Item label="存证ID：">
                                <Form.Text>{JSON.parse(item.showData.origin).evId}</Form.Text>
                              </Form.Item>
                              <Form.Item label="存证Hash：">
                                <Form.Text>{item.hash}</Form.Text>
                              </Form.Item>
                              <Form.Item label="交易Hash：">
                                <Form.Text>{item.showData.txId}</Form.Text>
                              </Form.Item>
                            </Form>
                          </>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </section>
            )}

            {/* 底部操作按钮 */}
            <div className="upload_bottom">
              <section
                className="upload_but"
                onClick={() => {
                  setLoading(false);
                  open();
                  if (fileList.some((item) => item.status !== 'default')) {
                    setHashList([]);
                    setFileList([]);
                    setCount(0);
                  }
                }}
              >
                <img src={SELECT_FILE} />
                <p>选择文件</p>
              </section>
              ;
              {fileList.length > 0 && (
                <section
                  className="upload_check"
                  onClick={() => {
                    if (!loading) {
                      setHashList([]);
                      verifyHandle();
                    }
                  }}
                >
                  <p>开始核验</p>
                  <img src={RIGHT_ICON} />
                </section>
              )}
            </div>
          </div>
        )}
      </Upload>
      <ErrorSuccessModal
        type={type}
        visible={errorVisble}
        fileList={fileList}
        onClose={() => {
          setErrorVisble(false);
        }}
      ></ErrorSuccessModal>
      <LoadingModal
        visible={loading}
        onClose={() => {
          setLoading(false);
        }}
      ></LoadingModal>
    </>
  );
}

export function ErrorSuccessModal({
  visible,
  onClose,
  fileList,
  type,
}: {
  visible: boolean;
  onClose: () => void;
  fileList: any;
  type: string;
}) {
  return (
    <Modal
      visible={visible}
      onClose={onClose}
      className={type === 'success' ? 'bg-modal bg-modal-success' : 'bg-modal '}
    >
      <Modal.Body className="error-modal-body">
        <img src={type == 'success' ? PASS_ICON : UNPASS_ICON} className="hash-unpass-icon" />
        <br />
        <br />
        <section className="upload_file_main ">
          {fileList?.map((item: any, index: number) => (
            <div key={index} className={item.status}>
              <div className="FileSec ">
                <section className="FileSec_left ">
                  <img src={FILE_ICON} />
                  <p className="file-name">{item.name}</p>
                </section>
                {item.status === 'error' && (
                  <section className="FileSec_right unpass-tag">
                    <p>未通过</p>
                  </section>
                )}

                {item.status === 'success' && (
                  <section className="FileSec_right pass-tag">
                    <p>审核通过</p>
                  </section>
                )}
              </div>
              {item.status !== 'default' && (
                <>
                  <section className="main">
                    <section className="main_title">
                      <img src={item.status === 'error' ? ERROR : SUCCESS} className="successTips" />

                      <strong>{item.status === 'error' ? '核验未通过' : '核验已通过'}</strong>
                      <span>{item.status === 'error' ? '该文件未经上链或已被篡改' : '该证据自上链之日起未经篡改'}</span>
                    </section>
                  </section>
                  {item.status === 'success' && (
                    <>
                      <Form readonly className="success-data">
                        <Form.Item label="上链时间：">
                          <Form.Text>{item.showData.txTime}</Form.Text>
                        </Form.Item>
                        <Form.Item label="区块高度：">
                          <Form.Text>{item.showData.blockHeight}</Form.Text>
                        </Form.Item>
                        <Form.Item label="存证ID：">
                          <Form.Text>{JSON.parse(item.showData.origin).evId}</Form.Text>
                        </Form.Item>
                        <Form.Item label="存证Hash：">
                          <Form.Text>{item.hash}</Form.Text>
                        </Form.Item>
                        <Form.Item label="交易Hash：">
                          <Form.Text>{item.showData.txId}</Form.Text>
                        </Form.Item>
                      </Form>
                    </>
                  )}
                </>
              )}
            </div>
          ))}
        </section>
      </Modal.Body>
    </Modal>
  );
}
