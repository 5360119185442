import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

interface ResponseType<T> {
  retCode: number;
  retMsg: string;
  data: T;
}

const BASEURL_TEST = 'https://zxscanapi.qq.com/api/v1/browser';
const BASEURL_PRD = 'https://zxscanapi.qq.com/api/v1/browser';

// 请求拦截
// axios.interceptors.request.use();
// 响应拦截
// axios.interceptors.response.use();

const request = <T>({ method, params, data, ...rest }: AxiosRequestConfig): Promise<AxiosResponse<ResponseType<T>>> => {
  const RES: AxiosRequestConfig = {
    method: method ?? 'GET',
    baseURL: process.env.REACT_APP_BUILD_MODE === 'production' ? BASEURL_PRD : BASEURL_TEST,
    params,
    data,
    ...rest,
  };

  return new Promise((resolve, reject) => {
    axios(RES)
      .then((res: AxiosResponse<ResponseType<T>>) => {
        resolve(res);
      })
      .catch((err: AxiosError) => reject(err));
  });
};

export default request;
