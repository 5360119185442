import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Header from './header';
import Body from './body';
import Footer from './footer';
import './index.scss';

interface CardProps {
  className?: string;
  full?: boolean;
  style?: { [key: string]: string };
  children?: ReactNode | ReactNode[];
}

function Card(props: CardProps) {
  const { children, className, style, full, ...rest } = props;
  const cls = classNames('t-card', className, { 't-card--full': full });
  return (
    <div className={cls} style={style} {...rest}>
      {children}
    </div>
  );
}

Card.Header = Header;
Card.Body = Body;
Card.Footer = Footer;

export default Card;
