import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@tencent/tdesign-react/es/style/index.css';
import '@tencent/tdesign-react/dist/tdesign.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// 导入样式
import '@tencent/tea-component/lib/tea.css';
import { BrowserRouter } from 'react-router-dom';
import isMobile from 'ismobilejs';

console.log(isMobile());
// 如果是在手机端打开
if (isMobile().phone) {
  const { origin, pathname, search } = window.location;
  if (!/^\/h5/.test(pathname)) {
    window.location.replace(`${origin}/h5?pathname=${encodeURIComponent(`/h5${pathname}${search}`)}`);
  } else {
    window.location.replace(`${origin}/h5?pathname=${encodeURIComponent(`${pathname}${search}`)}`);
  }
} else {
  const { origin, pathname, search } = window.location;
  if (/^\/h5/.test(pathname)) {
    window.location.replace(origin + pathname.replace(/^\/h5/, '') + search);
  } else {
    ReactDOM.render(
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
