import React from 'react';
import { Link } from 'react-router-dom';

export default function LinkHeight({ height, text }: { height: number; text?: string }) {
  return (
    <Link to={`/blocks/${height}`} target="_blank">
      {text || height}
    </Link>
  );
}
