import React, { ReactNode } from 'react';

interface BodyProps {
  children: ReactNode | ReactNode[];
  left?: ReactNode | ReactNode[];
  right?: ReactNode | ReactNode[];
}

export default function Body(props: BodyProps) {
  const { children, left, right } = props;
  return (
    <div className="t-card__body">
      {(!!left || !!right) && (
        <div className="t-card__justify">
          <div className="t-card__justify-col t-card__justify-col--left">{left}</div>
          <div className="t-card__justify-col t-card__justify-col--right">{right}</div>
        </div>
      )}
      {children}
    </div>
  );
}
