import { useEffect, useRef } from 'react';
import { SorterFun, DataType } from '@tencent/tdesign-react/es/table';
import { Options } from '@tencent/tdesign-react/es/_type/components/select';

export function checkTxAddr(addr: string | undefined): boolean {
  if (!addr) return false;
  const exp = /^zx/i;
  return exp.test(addr);
}

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const TxTypesMap = new Map([
  ['series_claim', 'NFT系列声明'],
  ['nft_claim', 'NFT发行'],
  ['nft_transfer', 'NFT转移'],
  ['nft_buy', 'NFT购买'],
  ['nft_buy_pay', '积分转移'],
  ['nft_update_sell', 'NFT销售信息变更'],
  ['add', '哈希存证'],
  ['dci_claim', '数字版权存证'],
  ['userBindV2', '用户绑定'],
  ['bind', '绑定公钥'],
  ['kv_save_v1', 'KV存证'],
]);

export const TXTYPEOPTIONS: Options[] = [
  {
    label: '全部方法',
    value: 'select_all',
  },
  {
    label: 'NFT系列声明',
    value: 'series_claim',
  },
  {
    label: 'NFT发行',
    value: 'nft_claim',
  },
  {
    label: 'NFT购买',
    value: 'nft_buy',
  },
  {
    label: 'NFT转移',
    value: 'nft_transfer',
  },
  {
    label: 'NFT销售信息变更',
    value: 'nft_update_sell',
  },
  {
    label: '哈希存证',
    value: 'add',
  },
  {
    label: '数字版权存证',
    value: 'dci_claim',
  },
  {
    label: '积分转移',
    value: 'nft_buy_pay',
  },
  {
    label: '用户绑定',
    value: 'userBindV2',
  },
  {
    label: 'KV存证',
    value: 'kv_save_v1',
  },
];

export const pickTypeMapByArray = (list: string[]): Options[] =>
  TXTYPEOPTIONS.filter((item) => list.indexOf(item.value as string) > -1);

export function isTxTypeExist(txType: string) {
  return TxTypesMap.has(txType);
}

export function getEllipsis(str: string, limit = 30) {
  return typeof str === 'string' && str.length > limit ? `${str.substr(0, limit)}...` : str;
}

export const SellStatusMap = new Map([
  ['1', '可销售'],
  ['2', '不可销售'],
]);

export function getDateSorter(key: string): SorterFun<DataType> {
  return (a, b) => {
    const ta = new Date(a[key]).getTime();
    const tb = new Date(b[key]).getTime();
    if (ta && tb) {
      return ta - tb ? -1 : 1;
    }
    return 1;
  };
}

export function numFormat(input: string | number) {
  if (typeof input !== 'string' && typeof input !== 'number') {
    return;
  }
  const str = typeof input !== 'string' ? input.toString() : input;
  const arr = str.split('.');
  const reg = /(?=(\B\d{3})+$)/g;
  arr[0] = arr[0].replace(reg, ',');
  return arr.join('.');
}

export function currencyFormat(num: number): string {
  const yuan = typeof num === 'number' ? num / 100 : 0;
  return yuan.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function getQueryString(field: string) {
  const { search } = window.location;
  const reg = new RegExp(`[?&]${field}=([^&]*)`, 'i');
  const string = reg.exec(search);
  return string ? string[1] : '';
}

export function isUrl(str: string) {
  const urlPattern = /(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/;
  return urlPattern.test(str);
}
