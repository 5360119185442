import { useCallback, useState } from 'react';
import {
  fetchBrowserEvQuery,
  fetchTxNumStatistic,
  getAddrAssetList,
  getAddrTxList,
  getBlockDetail,
  getBlockList,
  getHomePageData,
  getNFTDetail,
  getTxDetail,
  getTxList,
  search
} from './api';
import {
  AssetListRes,
  BlockDetailParam,
  BlockDetailRes,
  BlockListRes,
  HomePageRes,
  HookResponse,
  ListReqParam,
  NftDetailParam,
  NftDetailRes,
  SearchParam,
  SearchRes,
  TxDetailRes,
  TxListRes,
  TxNumStatisticParam,
  TxNumStatisticRes,
  FetchStatus,
  EvQueryReq,
  EvQueryRes
} from './interface';

export function useFetchHomePageData(): HookResponse<
  HomePageRes,
  ListReqParam
> {
  const [data, setData] = useState<HomePageRes>({
    totalTx: 0,
    currentTx: 0,
    height: 0,
    avgTime: 0
  });

  const fetch = useCallback(async () => {
    try {
      const result = await getHomePageData();
      setData(result.data.data);
    } catch {}
  }, []);

  return { data, fetch };
}

export function useFetchBlockList(): HookResponse<BlockListRes, ListReqParam> {
  const [data, setData] = useState<BlockListRes | null>(null);

  const fetch = useCallback(async (params?: ListReqParam) => {
    try {
      setData(null);
      const result = await getBlockList(params!);
      setData(result.data.data);
    } catch {}
  }, []);

  return { data, fetch };
}

export function useFetchBlockDetail(): HookResponse<
  BlockDetailRes,
  BlockDetailParam
> {
  const [data, setData] = useState<BlockDetailRes | null>(null);

  const fetch = useCallback(async (params?: BlockDetailParam) => {
    try {
      const result = await getBlockDetail(params!);
      setData(result.data.data);
    } catch {}
  }, []);

  return {
    data,
    fetch
  };
}

export function useFetchTxList(): HookResponse<TxListRes, ListReqParam> {
  const [data, setData] = useState<TxListRes | null>(null);
  const [retCode, setRetCode] = useState<number>(0);
  const [status, setStatus] = useState<FetchStatus>(FetchStatus.INIT);
  const fetch = useCallback(async (params?: ListReqParam) => {
    try {
      setData(null);
      setStatus(FetchStatus.LOADING);
      const result = await getTxList(params!);
      setData(result.data.data);
      setRetCode(result.data.retCode);
      if (result.data.retCode === 0) {
        setStatus(FetchStatus.SUCCESS);
      } else {
        setStatus(FetchStatus.FAILED);
      }
    } catch {
      setStatus(FetchStatus.FAILED);
    }
  }, []);

  return {
    data,
    fetch,
    retCode,
    status
  };
}

export function useFetchTxDetail(): HookResponse<
  TxDetailRes,
  { txId: string }
> {
  const [data, setData] = useState<TxDetailRes | null>(null);
  const [status, setStatus] = useState<FetchStatus>(FetchStatus.INIT);
  const fetch = useCallback(async (params?: { txId: string }) => {
    try {
      setStatus(FetchStatus.LOADING);
      const result = await getTxDetail(params!);
      setData(result.data.data);
      if (result.data.retCode === 0) {
        setStatus(FetchStatus.SUCCESS);
      } else {
        setStatus(FetchStatus.FAILED);
      }
    } catch {
      setStatus(FetchStatus.FAILED);
    }
  }, []);

  return {
    data,
    fetch,
    status
  };
}

export function useFetchAddrTxList(): HookResponse<TxListRes, ListReqParam> {
  const [data, setData] = useState<TxListRes | null>(null);

  const fetch = useCallback(async (params?: ListReqParam) => {
    try {
      setData(null);
      const result = await getAddrTxList(params!);
      setData(result.data.data);
    } catch {}
  }, []);

  return {
    data,
    fetch
  };
}

export function useFetchAddrAssetList(): HookResponse<
  AssetListRes,
  ListReqParam
> {
  const [data, setData] = useState<AssetListRes | null>(null);
  const [retCode, setRetCode] = useState<number>(0);
  const fetch = useCallback(async (params?: ListReqParam) => {
    try {
      setData(null);
      const result = await getAddrAssetList(params!);
      setData(result.data.data);
      setRetCode(result.data.retCode);
    } catch {}
  }, []);

  return {
    data,
    fetch,
    retCode
  };
}

export function useFetchNFTDetail(): HookResponse<
  NftDetailRes,
  NftDetailParam
> {
  const [data, setData] = useState<NftDetailRes | null>(null);
  const [status, setStatus] = useState<FetchStatus>(FetchStatus.INIT);
  const fetch = useCallback(async (params?: NftDetailParam) => {
    try {
      setStatus(FetchStatus.LOADING);
      const result = await getNFTDetail(params!);
      setData(result.data.data);
      if (result.data.retCode === 0) {
        setStatus(FetchStatus.SUCCESS);
      } else {
        setStatus(FetchStatus.FAILED);
      }
    } catch {
      setStatus(FetchStatus.FAILED);
    }
  }, []);
  return {
    data,
    fetch,
    status
  };
}

export function useFetchSearch(): HookResponse<SearchRes, SearchParam> {
  const [data, setData] = useState<SearchRes | null>(null);

  const fetch = useCallback(async (params?: SearchParam) => {
    try {
      const result = await search(params!);
      setData(result.data.data);
    } catch {}
  }, []);

  return {
    data,
    fetch
  };
}

export function useFetchTxStatistic(): HookResponse<
  TxNumStatisticRes,
  TxNumStatisticParam
> {
  const [data, setData] = useState<TxNumStatisticRes | null>(null);

  const fetch = useCallback(async (params?: TxNumStatisticParam) => {
    try {
      const result = await fetchTxNumStatistic(params!);
      setData(result.data.data);
    } catch {}
  }, []);

  return {
    data,
    fetch
  };
}

// 存证交易查询
export function browserEvQuery(): HookResponse<EvQueryRes, EvQueryReq> {
  const [data, setData] = useState<EvQueryRes | null>(null);
  const [status, setStatus] = useState<FetchStatus>(FetchStatus.INIT);
  const fetch = useCallback(async (params?: EvQueryReq) => {
    try {
      setStatus(FetchStatus.LOADING);
      const result = await fetchBrowserEvQuery(params!);
      setData(result.data.data);
      if (result.data.retCode === 0) {
        setStatus(FetchStatus.SUCCESS);
      } else {
        setStatus(FetchStatus.FAILED);
      }
    } catch {
      setStatus(FetchStatus.FAILED);
    }
  }, []);
  return {
    data,
    fetch,
    status
  };
}

