import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useFetchSearch } from '../../hooks';
import { Message } from '@tencent/tdesign-react';

import './head-search.scss';

export function HeadSearch() {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const { data, fetch } = useFetchSearch();

  const searchTypeMap = {
    1: 'addrs',
    2: 'transactions',
    3: 'nft',
    99: null,
  };

  const keydownHanler = (keycode: string) => {
    if (keycode === 'Enter') {
      setSearchValue(searchInput);
    }
  };

  useEffect(() => {
    if (searchValue) {
      let searchValueTransform = searchValue;
      if (/^zx/i.test(searchValue)) {
        searchValueTransform = searchValue.trim().replace(/^zx/i, 'ZX');
      } else {
        searchValueTransform = searchValue.toLowerCase();
      }
      fetch({
        keyword: searchValueTransform,
      });
    }
  }, [searchValue]);

  useEffect(() => {
    if (data) {
      const { searchType, keyword } = data;
      if (searchTypeMap[searchType]) {
        history.push(`/${searchTypeMap[searchType]}/${keyword}`);
      } else {
        Message.error('未找到数据', 2000);
      }
    }
  }, [data]);

  return (
    <div className="search-media">
      <div className="search-media__input">
        <i className="search-media__input--search"></i>
        {searchInput.length ? (
          <i
            className="search-media__input--close"
            onClick={() => {
              setSearchInput('');
              setSearchValue('');
            }}
          ></i>
        ) : (
          <></>
        )}
        <input
          placeholder="搜索地址/交易哈希/NFT ID"
          value={searchInput}
          onChange={(evt) => {
            const { value } = evt.target;
            setSearchInput(value);
          }}
          onKeyDown={(evt) => keydownHanler(evt.code)}
        />
      </div>
      <div className="search-media__btn" onClick={() => setSearchValue(searchInput)}>
        搜索
      </div>
    </div>
  );
}
