import { createContext } from 'react';

interface IAction {
  type: string;
  isError: boolean;
}

interface IState {
  isError: boolean;
}

interface IContextProps {
  state: IState;
  dispatch: (p: IAction) => void;
}

const defaultState: IState = {
  isError: false,
};

const defaultContextValue: IContextProps = {
  state: defaultState,
  dispatch: () => {},
};

const ctx = createContext(defaultContextValue);

const reducer = (state: IState, action: IAction) => {
  if (action.type === 'SET_ERROR') {
    return {
      ...state,
      isError: action.isError,
    };
  }
  return state;
};

export { ctx as Context, defaultState, reducer };
