import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@tencent/tdesign-react';
import { checkTxAddr, getEllipsis } from '../../../utils/utils';

export default function LinkTo({ to = '', ccmName, ccName = '' }: { to: string; ccmName: string; ccName?: string }) {
  const showAddr = ccmName === 'nft_transfer' || ccmName === 'nft_buy' || ccmName === 'nft_buy_pay';
  const isTxAddr = checkTxAddr(to);
  const sTo = getEllipsis(to, 20);
  return (
    <>
      {showAddr ? (
        isTxAddr ? (
          <Link to={`/addrs/${to}`} target="_blank">
            <Tooltip content={to} placement="top" showArrow destroyOnClose>
              {sTo}
            </Tooltip>
          </Link>
        ) : (
          sTo
        )
      ) : (
        ccName
      )}
    </>
  );
}
