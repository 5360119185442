import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Select, Tooltip } from '@tencent/tdesign-react';
import { Icon, IconFont } from 'tdesign-icons-react';
import { DetailHeadCard } from '../../common/components/detail-head-card';
import { CopyText } from '../../common/components/copy-text';
import Card from '../../common/components/card';
import { useFetchBlockDetail, useFetchTxList } from '../../common/hooks';
import { CommonTable } from '../../common/components/common-table/common-table';
import { useParams } from 'react-router';
import { TxTypesMap, currencyFormat, TXTYPEOPTIONS } from '../../utils/utils';
import LinkTxId from '../../common/components/links/link-txid';
import LinkTo from '../../common/components/links/link-to';
import LinkFrom from '../../common/components/links/link-from';
import { FetchStatus } from '../../common/interface';
import FormItem from '@tencent/tdesign-react/es/form/FormItem';
import '../../common/components/common-table/common-table.scss';

export function BlockDetailPage() {
  const { blockHeight } = useParams<{ blockHeight: string }>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const { data, fetch } = useFetchBlockDetail();
  const { data: txData, fetch: fetchTxList, status } = useFetchTxList();
  const [ccmName, setCcmName] = useState('select_all');

  const updateCcName = useCallback((value) => {
    setCcmName(value);
    setCurrentPage(1);
  }, []);
  useEffect(() => {
    fetch({
      height: Number(blockHeight),
    });
  }, [blockHeight]);
  useEffect(() => {
    const name = ccmName === 'select_all' ? '' : ccmName;
    fetchTxList({
      height: Number(blockHeight),
      page: currentPage,
      pageSize,
      ccmName: name,
    });
  }, [blockHeight, currentPage, pageSize, ccmName]);
  const totalContent = useMemo(() => {
    if (txData && 'total' in txData) {
      return `最近${Math.min(txData?.total || 0, 20000)}条，共${txData?.total}条`;
    }
    return '';
  }, [txData]);
  return (
    <>
      <h3 className="Detail_title">区块详情</h3>
      {!data && status !== FetchStatus.FAILED && (
        <Card>
          <Card.Body>
            <Icon name="loading" />
            &nbsp;加载中
          </Card.Body>
        </Card>
      )}
      {data && (
        <>
          <DetailHeadCard
            header={
              <>
                <h3>
                  区块高度 <CopyText content={`${data?.height}`} />
                </h3>
              </>
            }
            contents={[
              {
                label: '区块哈希',
                value: <CopyText content={data?.blockHash || ''} ellipsis showTips />,
                noEllipsis: true,
              },
              {
                label: '大小',
                value: data?.blockSize,
              },
              {
                label: '上链时间',
                value: data?.blockTime,
              },
              {
                label: '交易数量',
                value: data?.txNumber,
              },
            ]}
          />
          <Card>
            <Card.Body>
              <CommonTable
                title="交易记录"
                rowKey="txId"
                data={txData?.txList || []}
                columns={[
                  {
                    align: 'left',
                    colKey: 'txId',
                    title: '交易哈希',
                    ellipsis: true,
                    width: 300,
                    render: ({ row }) => (
                      <div className="tr-status">
                        {row.validationCode !== 0 && (
                          <Tooltip content="交易失败" placement="top" showArrow destroyOnClose>
                            <IconFont className="tr-status-icon" name="error-circle" />
                          </Tooltip>
                        )}
                        <LinkTxId txId={row.txId} />
                      </div>
                    ),
                  },
                  {
                    align: 'left',
                    colKey: 'from',
                    title: '从',
                    ellipsis: true,
                    width: 300,
                    render: ({ row }) => <LinkFrom from={row.from} />,
                  },
                  {
                    align: 'left',
                    colKey: 'to',
                    title: '到',
                    ellipsis: true,
                    width: 300,
                    render: ({ row }) => <LinkTo to={row.to} ccName={row.ccName} ccmName={row.ccmName} />,
                  },
                  {
                    align: 'left',
                    colKey: 'txTime',
                    title: '交易时间',
                    minWidth: 200,
                    ellipsis: true,
                  },
                  {
                    align: 'left',
                    colKey: 'ccmName',
                    title: '方法',
                    ellipsis: true,
                    minWidth: 120,
                    render: ({ row }) => TxTypesMap.get(row?.ccmName) ?? '其他',
                  },
                  {
                    align: 'left',
                    colKey: 'amount',
                    title: '交易金额',
                    minWidth: 120,
                    render: ({ row }) => `￥${currencyFormat(row.amount)}`,
                  },
                ]}
                hover
                formNode={
                  <Form layout="inline" labelWidth={60} style={{ justifyContent: 'end' }}>
                    <FormItem label="" name="ccmName">
                      <Select
                        defaultValue={TXTYPEOPTIONS[0].value}
                        value={ccmName}
                        options={TXTYPEOPTIONS}
                        onChange={updateCcName}
                      ></Select>
                    </FormItem>
                  </Form>
                }
                pagination={{
                  pageSize,
                  total: Math.min(data?.txNumber || 0, 20000),
                  totalContent,
                  current: currentPage,
                  showJumper: true,
                  onChange: (pageInfo) => {
                    const { pageSize, current } = pageInfo;
                    setPageSize(pageSize);
                    setCurrentPage(current);
                  },
                }}
                onSortChange={() => {}}
              />
            </Card.Body>
          </Card>
        </>
      )}
      {!data && status === FetchStatus.FAILED && (
        <Card>
          <Card.Body>
            <Icon name="error-circle" />
            &nbsp;找不到数据
          </Card.Body>
        </Card>
      )}
    </>
  );
}
