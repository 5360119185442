import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DataType, PrimaryTableCol } from '@tencent/tdesign-react/es/table';
import { CommonTable } from '../../common/components/common-table/common-table';
import { useFetchTxList } from '../../common/hooks';
import { TxTypesMap, getDateSorter, currencyFormat, TXTYPEOPTIONS } from '../../utils/utils';
import LinkFrom from '../../common/components/links/link-from';
import LinkTxId from '../../common/components/links/link-txid';
import LinkTo from '../../common/components/links/link-to';
import LinkHeight from '../../common/components/links/link-height';
import { Form, IconFont, Select, Tooltip } from '@tencent/tdesign-react';
import FormItem from '@tencent/tdesign-react/es/form/FormItem';

export function TransactionPage() {
  const { data, fetch } = useFetchTxList();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [ccmName, setCcmName] = useState('select_all');
  // const [totalContent, setTotalContent] = useState('');

  const updateCcName = useCallback((value) => {
    setCcmName(value);
    setCurrentPage(1);
  }, []);
  useEffect(() => {
    const name = ccmName === 'select_all' ? '' : ccmName;
    fetch({
      ccmName: name,
      page: currentPage,
      pageSize,
    });
  }, [currentPage, pageSize, ccmName]);

  const totalContent = useMemo(() => {
    if (data && 'total' in data) {
      return `最近${Math.min(data.total, 20000)}条，共${data.total}条`;
    }
    return '';
  }, [data]);

  const defaultColumns: PrimaryTableCol<DataType>[] = [
    {
      align: 'left',
      colKey: 'txId',
      title: '交易哈希',
      ellipsis: true,
      width: 300,
      render: ({ row }) => (
        <div className="tr-status">
          {row.validationCode !== 0 && (
            <Tooltip content="交易失败" placement="top" showArrow destroyOnClose>
              <IconFont className="tr-status-icon" name="error-circle" />
            </Tooltip>
          )}
          <LinkTxId txId={row.txId} />
        </div>
      ),
    },
    {
      align: 'left',
      colKey: 'txTime',
      title: '交易时间',
      ellipsis: true,
      width: 200,
      sorter: getDateSorter('txTime'),
      sortType: 'all',
    },
    {
      align: 'left',
      colKey: 'from',
      title: '从',
      ellipsis: true,
      width: 220,
      render: ({ row }) => <LinkFrom from={row.from} />,
    },
    {
      align: 'left',
      colKey: 'to',
      title: '到',
      ellipsis: true,
      width: 220,
      render: ({ row }) => <LinkTo to={row.to} ccName={row.ccName} ccmName={row.ccmName} />,
    },
    {
      align: 'left',
      colKey: 'ccmName',
      title: '方法',
      ellipsis: true,
      width: 150,
      render: ({ row }) => TxTypesMap.get(row?.ccmName) ?? '其他',
    },
    {
      align: 'left',
      colKey: 'height',
      title: '区块高度',
      width: 120,
      render: ({ row }) => <LinkHeight height={row.height} />,
    },
    {
      align: 'left',
      colKey: 'amount',
      title: '交易金额',
      width: 120,
      render: ({ row }) => `￥${currencyFormat(row.amount)}`,
    },
  ];
  return (
    <>
      <CommonTable
        title="最新交易"
        rowKey="txId"
        data={data?.txList || []}
        columns={defaultColumns}
        hover
        formNode={
          <Form layout="inline" labelWidth={60} style={{ justifyContent: 'end' }}>
            <FormItem label="" name="ccmName">
              <Select
                defaultValue={TXTYPEOPTIONS[0].value}
                value={ccmName}
                options={TXTYPEOPTIONS}
                onChange={updateCcName}
              ></Select>
            </FormItem>
          </Form>
        }
        pagination={{
          pageSize,
          current: currentPage,
          total: Math.min(data?.total || 0, 20000),
          totalContent,
          showJumper: true,
          onChange: (pageInfo) => {
            const { current, pageSize } = pageInfo;
            setCurrentPage(current);
            setPageSize(pageSize);
          },
        }}
        onSortChange={() => {}}
        loading={!data}
      />
    </>
  );
}
