export interface HomePageRes {
  totalTx: number;
  currentTx: number;
  height: number;
  avgTime: number;
}

export interface BlockListItem {
  height: number;
  blockHash: string;
  blockTime: string;
  txNumber: number;
}

export interface Page {
  [key: string]: string;
}

export enum FetchStatus {
  INIT,
  LOADING,
  SUCCESS,
  FAILED,
}

export interface HookResponse<T, U> {
  fetch: (params?: U) => Promise<void>;
  data: T | null;
  retCode?: number;
  status?: FetchStatus;
}

export interface TxListItem {
  txId: string;
  txTime: string;
  from: string;
  to: string;
  ccmName: string;
  ccName: string;
  height: number;
  amount: number;
}

export interface ListReqParam {
  page?: number;
  pageSize?: number;
  addr?: string;
  nftId?: string;
  height?: number;
  ccmName?: string;
}

export interface BlockListRes {
  blockList: BlockListItem[];
  total: number;
  height: number;
  blockHash: string;
  blockTime: string;
  txNumber: number;
}

export interface BlockDetailParam {
  height: number;
  page?: number;
}

export interface BlockDetailRes {
  height: number;
  blockHash: string;
  blockTime: string;
  txNumber: number;
  blockSize: number;
  txList: TxListItem[];
  page: Page;
}

export interface TxListRes {
  txList: TxListItem[];
  total: number;
}

export interface TxDetailHash {
  evHash: string;
}

export interface TxDetailUserBind {
  addr: string;
}

export interface TxDetailRight {
  evId: string;
  author: string;
  name: string;
  type: string;
  property: string;
  url: string;
  rights: {
    type: string;
    persons: string[];
  }[];
}

export interface TxDetailSeriesClaim {
  seriesId: string;
  seriesName: string;
  creatorAddr: string;
  totalCount: number;
  coverUrl: string;
}

export interface TxDetailClaim {
  nftId: string;
  hash: string;
  author: Array<string>;
  name: string;
  seriesId: string;
  count: number;
  coverUrl: string;
}

export interface TxDetailSell {
  nftId: string;
}

export interface TxDetailUpdateSell {
  nftId: string;
  status: string;
  price: string;
}

export interface TxDetailUpdatePrice {
  nftId: string;
  price: string;
}

export interface TxDetailKv {
  kvKey: string;
  kvValue: string;
  kvValueJson?: string;
  kvValueHash?: string;
  publicKey?: string;
}

export type OriginData =
  | TxDetailUserBind
  | TxDetailSeriesClaim
  | TxDetailClaim
  | TxDetailSell
  | TxDetailUpdateSell
  | TxDetailUpdatePrice
  | TxDetailHash
  | TxDetailRight
  | TxDetailKv
  | string;

export enum ContractFunctionName {
  series_claim = 'series_claim',
  nft_claim = 'nft_claim',
  nft_transfer = 'nft_transfer',
  nft_buy = 'nft_buy',
  nft_update_sell = 'nft_update_sell',
  add = 'add',
  dci_claim = 'dci_claim',
}

export interface TxDetailRes extends TxListItem {
  originData: OriginData;
}

export interface NftListItem {
  nftId: string;
  author: string;
  name: string;
  txTime: string;
  txId: string;
  amount: number;
}

export interface AssetListRes {
  nftList: NftListItem[];
  total: number;
}

export interface NftDetailParam {
  nftId: string;
}

export interface NftDetailRes extends NftListItem {
  hash: string;
  seriesName: string;
  seriesId: string;
  publishTime: string;
  publishAddr: string;
  url: string;
  nftOwner: string;
  txList: TxListItem[];
  displayUrl: string;
}

export interface SearchParam {
  keyword: string;
}

export interface SearchRes {
  searchType: 1 | 2 | 3 | 99;
  keyword: string;
}

export interface TxNumStatisticParam {
  unit: string;
  limit: number;
}

export interface TxNumStatisticRes {
  txList: {
    time: string;
    num: number;
  }[];
}

export interface EvQueryRes {
  retCode: number;
  retMsg: string;
  data: {
    txId: string;
    blockHeight: number;
    txTime: string;
    ccName: string;
    ccmName: string;
    origin: string;
  };
}

export interface EvQueryReq {
  evId?: string;
  hash?: string;
  txId?: string;
  accesscode?: string;
}
