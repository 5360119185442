import request from '../utils/request';
import {
  AssetListRes,
  BlockDetailParam,
  BlockDetailRes,
  BlockListRes,
  EvQueryReq,
  EvQueryRes,
  HomePageRes,
  ListReqParam,
  NftDetailParam,
  NftDetailRes,
  SearchParam,
  SearchRes,
  TxDetailRes,
  TxListRes,
  TxNumStatisticParam,
  TxNumStatisticRes
} from './interface';

export function getHomePageData() {
  return request<HomePageRes>({
    method: 'GET',
    url: '/index'
  });
}

export function getBlockList(params: ListReqParam) {
  return request<BlockListRes>({
    method: 'GET',
    url: '/block/list',
    params
  });
}

export function getBlockDetail(params: BlockDetailParam) {
  return request<BlockDetailRes>({
    method: 'GET',
    url: '/block',
    params
  });
}

export function getTxList(params: ListReqParam) {
  return request<TxListRes>({
    method: 'GET',
    url: '/tx/list',
    params
  });
}

export function getTxDetail(params: { txId: string }) {
  return request<TxDetailRes>({
    method: 'GET',
    url: '/tx',
    params
  });
}

export function getAddrTxList(params: ListReqParam) {
  return request<TxListRes>({
    method: 'GET',
    url: '/addr/tx/list',
    params
  });
}

export function getAddrAssetList(params: ListReqParam) {
  return request<AssetListRes>({
    method: 'GET',
    url: '/addr/asset/list',
    params
  });
}

export function getNFTDetail(params: NftDetailParam) {
  return request<NftDetailRes>({
    method: 'GET',
    url: '/nft',
    params
  });
}

export function search(params: SearchParam) {
  return request<SearchRes>({
    url: '/search',
    params
  });
}

export function fetchTxNumStatistic(params: TxNumStatisticParam) {
  return request<TxNumStatisticRes>({
    url: '/tx/num/statistics',
    params
  });
}

// 存证交易查询
export function fetchBrowserEvQuery(params: EvQueryReq) {
  return request<EvQueryRes>({
    url: '/ev/query',
    params
  });
}
