import { Row, Col } from '@tencent/tdesign-react';
import React, { ReactNode } from 'react';
import { MetricsBoard } from './metrics-board';
import './metrics-board.scss';

interface MetricsBoard {
  title: ReactNode;
  value: ReactNode;
  unit?: ReactNode;
}

interface PropsTypes {
  divider?: boolean;
  groups: MetricsBoard[];
}

export function MetricsBoardGroup(props: PropsTypes) {
  const { groups, divider } = props;
  const colspan = Math.floor(12 / groups.length);
  return (
    <div className="metrics-board-group">
      <Row gutter={16}>
        {groups.map((group, index) => (
          <Col span={colspan} key={`__key-${group.title}_${index}`}>
            <MetricsBoard
              {...group}
              style={{ borderRight: index !== groups.length - 1 && divider ? '1px solid #E7E7E7' : '' }}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}
