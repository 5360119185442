import React, { useEffect, useState } from 'react';
import { HeadSearch } from '../head-search/head-search';
import logo from '../../../assets/img/logo.svg';
import './home-header.scss';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import isMobile from 'ismobilejs';
const iconTrade =
  'https://imgcache.qq.com/qcloud/tcloud_dtc/static/static_source_business/5293b998-b6f7-4cf0-a208-e45954ec05ab.svg';
const iconBlock =
  'https://imgcache.qq.com/qcloud/tcloud_dtc/static/static_source_business/e95ef010-b686-443d-b34c-cfa7b4a88377.svg';

function HomeHeader() {
  const history = useHistory();
  const [pathName, setPathName] = useState<string>('/');
  const location = useLocation();
  useEffect(() => setPathName(location.pathname), [location]);
  return (
    <div className="header-nav">
      <div className="header-nav__logo">
        <img src={logo} alt="至信链" onClick={() => history.push('/')} />
      </div>
      {pathName !== '/' && (
        <div className="header-nav__search">
          <HeadSearch />
        </div>
      )}
      <div className="header-nav__list">
        <div className="header-nav__item">
          <span className="title" onClick={() => history.push('/')}>
            首页
          </span>
        </div>
        {!isMobile().phone && (
          <div className="header-nav__item">
            <span className="title" onClick={() => history.push('/validate')}>
              证据核验
            </span>
          </div>
        )}

        <div className="header-nav__item has-subnav">
          <span className="title" onClick={() => history.push('/')}>
            浏览
          </span>
          <div className="header-nav__subnav">
            <ol>
              <li onClick={() => history.push('/transactions')}>
                <i
                  className="icon"
                  style={{ backgroundImage: `url(${iconTrade})` }}
                ></i>
                <span>交易</span>
              </li>
              <li onClick={() => history.push('/blocks')}>
                <i
                  className="icon"
                  style={{ backgroundImage: `url(${iconBlock})` }}
                ></i>
                <span>区块</span>
              </li>
            </ol>
          </div>
        </div>
        <div className="header-nav__item">
          <span
            className="title"
            onClick={() => {
              if (process.env.REACT_APP_BUILD_MODE === 'test') {
                window.location.href =
                  'http://zxchain-test-6gy12odu4cc396a0-1258344699.tcloudbaseapp.com';
              } else {
                window.location.href = 'https://zxchain.qq.com/';
              }
            }}
          >
            官网
          </span>
        </div>
      </div>
    </div>
  );
}

export default HomeHeader;
