import React, { ErrorInfo } from 'react';
import { Message } from '@tencent/tdesign-react';

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<React.PropsWithChildren<{}>, State> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  state = { hasError: false };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Message duration={0} theme="error">
          页面出错，请刷新
        </Message>
      );
    }
    return this.props.children;
  }
}
