import React, { useEffect, useState } from 'react';
import { CommonTable } from '../../common/components/common-table/common-table';
import { useFetchBlockList } from '../../common/hooks';
import { getDateSorter } from '../../utils/utils';
import LinkHeight from '../../common/components/links/link-height';

export function BlockPage() {
  const { data, fetch } = useFetchBlockList();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  useEffect(() => {
    fetch({
      page: currentPage,
      pageSize,
    });
  }, [currentPage, pageSize]);
  return (
    <>
      <CommonTable
        title="最新区块"
        rowKey="height"
        data={data?.blockList || []}
        columns={[
          {
            colKey: 'height',
            title: '区块高度',
            width: 200,
            render: ({ row }) => <LinkHeight height={row.height} />,
          },
          {
            colKey: 'blockHash',
            title: '区块哈希',
            width: 620,
            render: ({ row }) => <LinkHeight height={row.height} text={row.blockHash} />,
          },
          {
            colKey: 'blockTime',
            title: '上链时间',
            width: 200,
            sorter: getDateSorter('blockTime'),
            sortType: 'all',
          },
          {
            colKey: 'txNumber',
            title: '交易数量',
            width: 200,
            render: ({ row }) => <LinkHeight height={row.height} text={row.txNumber} />,
          },
        ]}
        hover
        pagination={{
          pageSize,
          total: Math.min(data?.total || 0, 20000),
          totalContent: `最近${Math.min(data?.total || 0, 20000)}条，共${data?.total}条`,
          showJumper: true,
          current: currentPage,
          onChange: (pageInfo) => {
            const { current, pageSize } = pageInfo;
            setPageSize(pageSize);
            setCurrentPage(current);
          },
        }}
        onSortChange={() => {}}
        loading={!data}
      />
    </>
  );
}
