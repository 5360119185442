import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Tag } from '@tencent/tdesign-react';
import { getEllipsis } from '../../../utils/utils';

export default function LinkTxId({
  txId,
  flag = '',
  showTag = false,
}: {
  txId: string;
  flag?: string;
  showTag?: boolean;
}) {
  return (
    <Link to={`/transactions/${txId}`} target="_blank">
      <Tooltip content={txId} placement="top" showArrow destroyOnClose style={{ width: '1000px' }}>
        {getEllipsis(txId)}
        {showTag &&
          (flag === 'out' ? (
            <Tag theme="primary" size="small">
              OUT
            </Tag>
          ) : (
            <Tag theme="success" variant="dark" size="small">
              IN
            </Tag>
          ))}
      </Tooltip>
    </Link>
  );
}
