import React, { useEffect } from 'react';
import { Button, Row, Col } from '@tencent/tdesign-react';
import { Icon } from 'tdesign-icons-react';
import { BasicArea } from '@tencent/tea-chart';
import { HeadSearch } from '../../common/components/head-search/head-search';
import Card from '../../common/components/card';
import { MetricsBoardGroup } from '../../common/components/metrics-board/metrics-board-group';
import { useFetchHomePageData, useFetchTxStatistic } from '../../common/hooks';
import { useHistory } from 'react-router';
import logo from '../../assets/img/logo.svg';
import './home.scss';
import { useInterval, numFormat } from '../../utils/utils';

import TxIcon from '../../assets/img/icon-tx.svg';
import BlockIcon from '../../assets/img/icon-block.svg';

export function HomePage() {
  const { data, fetch } = useFetchHomePageData();
  const { data: statisticData, fetch: fetchStatistic } = useFetchTxStatistic();
  const history = useHistory();

  useInterval(() => void fetch(), 10000);
  useInterval(
    () =>
      void fetchStatistic({
        limit: 14,
        unit: 'day',
      }),
    10000,
  );

  useEffect(() => {
    fetch();
    fetchStatistic({
      limit: 14,
      unit: 'day',
    });
  }, []);

  return (
    <>
      <div className="home-search__box">
        <div className="home-search__logo">
          <img src={logo} alt="至信链" />
        </div>
        <HeadSearch />
      </div>
      <div className="home-media">
        <Row gutter={16}>
          <Col span={6}>
            <Card style={{ height: '181px' }}>
              <Card.Body
                left={
                  <>
                    <img src={TxIcon} alt="交易" className="home-search__icon" />
                    <h3>交易</h3>
                    <span>
                      (<Icon name="refresh" size="16" /> 10s自动刷新)
                    </span>
                  </>
                }
                right={
                  <Button theme="default" variant="base" onClick={() => history.push('/transactions')}>
                    查看详情
                  </Button>
                }
              >
                <MetricsBoardGroup
                  groups={[
                    {
                      title: '24h交易数',
                      value: numFormat(data?.currentTx as number),
                      unit: '笔',
                    },
                    {
                      title: '累计交易总数',
                      value: numFormat(data?.totalTx as number),
                      unit: '笔',
                    },
                  ]}
                  divider={true}
                />
              </Card.Body>
            </Card>

            <Card style={{ height: '181px' }}>
              <Card.Body
                left={
                  <>
                    <img src={BlockIcon} alt="区块" className="home-search__icon" />
                    <h3>区块</h3>
                    <span>
                      (<Icon name="refresh" size="16" /> 10s自动刷新)
                    </span>
                  </>
                }
                right={
                  <Button theme="default" variant="base" onClick={() => history.push('/blocks')}>
                    查看详情
                  </Button>
                }
              >
                <MetricsBoardGroup
                  groups={[
                    {
                      title: '最新区块高度',
                      value: data?.height,
                    },
                    {
                      title: '平均出块时间',
                      value: data?.avgTime,
                      unit: '秒',
                    },
                  ]}
                  divider={true}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col span={6}>
            <Card style={{ height: '378px' }}>
              <Card.Body
                left={
                  <>
                    <h3>日交易数量统计</h3>
                    <span>(最近 14 天)</span>
                  </>
                }
              >
                <BasicArea
                  height={300}
                  position="time*num"
                  dataSource={statisticData?.txList || []}
                  tooltip={{
                    formatter: (values) => values.map((value) => ({ ...value, label: '交易量' })),
                  }}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
