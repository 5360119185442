import React, { useReducer } from 'react';
import { Layout } from '@tencent/tdesign-react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './App.css';

import ErrorBoundary from './common/components/error-boundary';

import { HomePage } from './routes/home/home';
import { TransactionPage } from './routes/transaction/transaction';
import { TransactionDetailPage } from './routes/transaction/transaction-detail';
import { BlockPage } from './routes/block/block';
import { BlockDetailPage } from './routes/block/block_detail';
import { AddrsDetailPage } from './routes/addrs/addrs';
import { NftDetailPage } from './routes/nfts/nft-detail';
import HomeHeader from './common/components/home-header/home-header';
import { defaultState, reducer, Context } from './common/reducer';
import { ValidatePage } from './routes/validate/validate';

const { Content, Footer } = Layout;

function App() {
  const [state, dispatch] = useReducer(reducer, defaultState);
  return (
    <Context.Provider value={{ state, dispatch }}>
      <Layout style={{ minHeight: '100vh', backgroundColor: '#fff' }}>
        <HomeHeader />
        <Content>
          <ErrorBoundary>
            <Switch>
              {/* 由于移动端部署问题，占用了/h5路径，所以此处不能存在/h5路由 */}
              <Route exact path="/" key="home" component={HomePage} />,
              <Route exact path="/transactions" key="transcation" component={TransactionPage} />
              <Route exact path="/validate" key="validate" component={ValidatePage} />
              <Route path="/transactions/:txId" key="transcation_detail" component={TransactionDetailPage} />
              <Route exact path="/blocks" key="block" component={BlockPage} />
              <Route path="/blocks/:blockHeight" key="block_detail" component={BlockDetailPage} />
              <Route path="/addrs/:addr" key="addr_detail" component={AddrsDetailPage} />
              <Route path="/nft/:nftId" key="nft_detail" component={NftDetailPage} />
              <Redirect to="/" />
            </Switch>
          </ErrorBoundary>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Copyright @ 2021-2022 Tencent. All Rights Reserved &nbsp;&nbsp;
          <a href="https://zxchain.qq.com/service" target="_blank" rel="noreferrer">
            服务声明
          </a>{' '}
          &nbsp;&nbsp;
          <a href="https://zxchain.qq.com/privacy" target="_blank" rel="noreferrer">
            隐私政策
          </a>
        </Footer>
      </Layout>
    </Context.Provider>
  );
}

export default App;
