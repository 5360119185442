import { Col, Form, Row, Select, Tooltip } from '@tencent/tdesign-react';
import { Icon, IconFont } from 'tdesign-icons-react';
import { DataType, PrimaryTableCol } from '@tencent/tdesign-react/es/table';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchNFTDetail, useFetchTxList } from '../../common/hooks';
import Card from '../../common/components/card';
import { CommonTable } from '../../common/components/common-table/common-table';
import { CopyText } from '../../common/components/copy-text';
import { TxTypesMap, getDateSorter, currencyFormat, isUrl, pickTypeMapByArray } from '../../utils/utils';
import LinkTxId from '../../common/components/links/link-txid';
import LinkTo from '../../common/components/links/link-to';
import LinkFrom from '../../common/components/links/link-from';
import LinkHeight from '../../common/components/links/link-height';
import { FetchStatus } from '../../common/interface';
import NftDefaultImg from '../../assets/img/nft-default.png';
import FormItem from '@tencent/tdesign-react/es/form/FormItem';

const options = pickTypeMapByArray(['select_all', 'nft_claim', 'nft_buy', 'nft_transfer', 'nft_update_sell']);
export function NftDetailPage() {
  const { data, fetch, status } = useFetchNFTDetail();
  const { data: txData, fetch: fetchTxList } = useFetchTxList();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { nftId } = useParams<{ nftId: string }>();
  const [formattedData, setFormattedData] = useState<{ key: string; value: string; copy?: boolean; link?: string; type?: string; }[]>(
    [],
  );
  const [ccmName, setCcmName] = useState('select_all');

  const updateCcName = useCallback((value) => {
    setCcmName(value);
    setCurrentPage(1);
  }, []);
  const defaultColumns: PrimaryTableCol<DataType>[] = [
    {
      align: 'left',
      colKey: 'txId',
      title: '交易哈希',
      width: 300,
      ellipsis: true,
      render: ({ row }) => (
        <div className="tr-status">
          {row.validationCode !== 0 && (
            <Tooltip content="交易失败" placement="top" showArrow destroyOnClose>
              <IconFont className="tr-status-icon" name="error-circle" />
            </Tooltip>
          )}
          <LinkTxId txId={row.txId} />
        </div>
      ),
    },
    {
      align: 'left',
      colKey: 'txTime',
      title: '交易时间',
      width: 200,
      ellipsis: true,
      sorter: getDateSorter('txTime'),
      sortType: 'all',
    },
    {
      align: 'left',
      colKey: 'from',
      title: '从',
      width: 300,
      ellipsis: true,
      render: ({ row }) => <LinkFrom from={row.from} />,
    },
    {
      align: 'left',
      colKey: 'to',
      title: '到',
      width: 300,
      ellipsis: true,
      render: ({ row }) => <LinkTo to={row.to} ccName={row.ccName} ccmName={row.ccmName} />,
    },
    {
      align: 'left',
      colKey: 'ccmName',
      title: '方法',
      ellipsis: true,
      minWidth: 100,
      render: ({ row }) => TxTypesMap.get(row?.ccmName) ?? '其他',
    },
    {
      align: 'left',
      colKey: 'height',
      title: '区块高度',
      minWidth: 120,
      render: ({ row }) => <LinkHeight height={row.height} />,
    },
    {
      align: 'left',
      colKey: 'amount',
      title: '交易金额',
      minWidth: 120,
      render: ({ row }) => `￥${currencyFormat(row.amount)}`,
    },
  ];

  useEffect(() => {
    fetch({
      nftId,
    });
  }, [nftId]);

  useEffect(() => {
    const name = ccmName === 'select_all' ? '' : ccmName;
    fetchTxList({
      ccmName: name,
      page: currentPage,
      pageSize,
      nftId,
    });
  }, [pageSize, currentPage, nftId, ccmName]);

  useEffect(() => {
    if (data) {
      const { hash, author, name, seriesName, seriesId, nftOwner, publishTime, publishAddr, url } = data;
      setFormattedData([
        {
          key: '作品哈希',
          value: hash,
          copy: true,
        },
        {
          key: '作者名',
          value: author,
        },
        {
          key: '作品名称',
          value: name,
        },
        {
          key: '系列名',
          type: 'seriesName',
          value: seriesName,
        },
        {
          key: '系列ID',
          type: 'seriesId',
          value: seriesId,
        },
        {
          key: '发行者',
          value: publishAddr,
          copy: true,
          link: `/addrs/${publishAddr}`,
        },
        {
          key: '发行时间',
          value: publishTime,
        },
        {
          key: '作品URL',
          value: url,
          link: isUrl(url) ? url : undefined,
          copy: isUrl(url),
        },
        {
          key: '所有者',
          value: nftOwner,
          copy: true,
          link: `/addrs/${nftOwner}`,
        },
      ]);
    }
  }, [data]);
  let totalContent = '';
  if (txData && 'total' in txData) {
    totalContent = `最近${Math.min(txData?.total || 0, 20000)}条，共${txData?.total}条`;
  }
  return (
    <>
      <h3 className="Detail_title">NFT详情</h3>
      {(status === FetchStatus.INIT || status === FetchStatus.LOADING) && (
        <Card>
          <Card.Body>
            <Icon name="loading" />
            &nbsp;加载中
          </Card.Body>
        </Card>
      )}
      {status === FetchStatus.SUCCESS && (
        <>
          <Card>
            <Card.Header>
              <h3>
                NFT ID <CopyText content={`${data?.nftId}`} />
              </h3>
            </Card.Header>
            <Card.Body>
              <Row gutter={32}>
                <Col span={3}>
                  {isUrl(data?.url || '') ? (
                    <a href={data?.url} target="_blank" rel="noreferrer">
                      <img src={data?.displayUrl} alt="" width="290" height="290" defaultValue={NftDefaultImg} />
                    </a>
                  ) : (
                    <img src={data?.displayUrl} alt="" width="290" height="290" defaultValue={NftDefaultImg} />
                  )}
                </Col>
                <Col span={9}>
                  {formattedData
                    .filter((item) => !((item.type === 'seriesName' || item.type === 'seriesId') && !item.value))
                    .map(({ key, value, link, copy }, idx) => (
                      <div key={key} style={{ marginLeft: '32px', marginTop: idx !== 0 ? '24px' : '0' }}>
                        <Row>
                          <Col span={1}>
                            <span style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>{key}</span>
                          </Col>
                          <Col span={11} style={{ wordBreak: 'break-all' }}>
                            {/* {value} */}
                            {copy ? <CopyText content={value} externalLink={!!link?.length} link={link} /> : value}
                            
                          </Col>
                        </Row>
                      </div>
                    ))}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <CommonTable
                title="交易记录"
                rowKey="txId"
                data={txData?.txList || []}
                columns={defaultColumns}
                hover
                formNode={
                  <Form layout="inline" labelWidth={60} style={{ justifyContent: 'end' }}>
                    <FormItem label="" name="ccmName">
                      <Select
                        defaultValue={options[0].value}
                        value={ccmName}
                        options={options}
                        onChange={updateCcName}
                      ></Select>
                    </FormItem>
                  </Form>
                }
                pagination={{
                  pageSize,
                  current: currentPage,
                  total: Math.min(txData?.total || 0, 20000),
                  totalContent,
                  showJumper: true,
                  onChange: (pageInfo) => {
                    const { current, pageSize } = pageInfo;
                    setCurrentPage(current);
                    setPageSize(pageSize);
                  },
                }}
                onSortChange={() => {}}
              />
            </Card.Body>
          </Card>
        </>
      )}
      {status === FetchStatus.FAILED && (
        <Card>
          <Card.Body>
            <Icon name="error-circle" />
            &nbsp;找不到数据
          </Card.Body>
        </Card>
      )}
    </>
  );
}
