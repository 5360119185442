import { Table } from '@tencent/tdesign-react';
import Pagination from '@tencent/tdesign-react/es/pagination/Pagination';
import { PrimaryTableProps } from '@tencent/tdesign-react/es/table';
import classNames from 'classnames';
import React from 'react';

import './common-table.scss';

interface CommonTableProps extends PrimaryTableProps {
  title?: string;
  className?: string;
  style?: { [key: string]: string };
  formNode?: React.ReactNode;
}

export function CommonTable(props: CommonTableProps) {
  const { title, className, formNode, style, pagination, ...rest } = props;
  const cls = classNames('common-table', className);
  const pls = classNames('table-pagination', className);
  const tls = classNames('table-top', className);
  const fls = classNames('table-form', className);
  return (
    <div className={cls} style={style}>
      <div className={tls}>
        {title && <h3>{title}</h3>}
        {formNode && <div className={fls}>{formNode}</div>}
      </div>
      <Table {...rest} />
      <div className={pls}>
        <Pagination {...pagination} />
      </div>
    </div>
  );
}
