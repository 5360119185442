import React from 'react';
import { Message, Tooltip } from '@tencent/tdesign-react';
import { Icon } from 'tdesign-icons-react';

export function CopyText({
  content,
  externalLink = false,
  link = '',
  ellipsis = false,
  showTips = false,
}: {
  content: string;
  externalLink?: boolean;
  link?: string;
  ellipsis?: boolean;
  showTips?: boolean;
}) {
  const copyToClipboard = (str: string) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', 'readonly');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected = document.getSelection()!.rangeCount > 0 ? document.getSelection()!.getRangeAt(0) : false;
    el.select();
    document.execCommand('copy');
    if (selected) {
      document.getSelection()!.removeAllRanges();
      document.getSelection()!.addRange(selected);
      Message.success('复制成功', 2000);
    } else {
      Message.error('复制失败', 2000);
    }
  };
  const shortContent =
    ellipsis && typeof content === 'string' && content.length > 50 ? `${content.substr(0, 50)}...` : content;
  const contentWithTips = showTips ? (
    <Tooltip content={content} placement="top" showArrow destroyOnClose>
      {shortContent}
    </Tooltip>
  ) : (
    shortContent
  );
  return (
    <>
      {externalLink ? (
        <a href={link} style={{ textDecoration: 'none', color: '#0052D9' }} target="_blank" rel="noreferrer">
          {contentWithTips}
        </a>
      ) : (
        contentWithTips
      )}{' '}
      <Icon name="file-copy" onClick={() => copyToClipboard(content)} style={{ cursor: 'pointer' }} />
    </>
  );
}
