import Card from '../../common/components/card';
import { CopyText } from '../../common/components/copy-text';
import { DetailHeadCard } from '../../common/components/detail-head-card';
import { Col, Row, Table } from '@tencent/tdesign-react';
import { Icon } from 'tdesign-icons-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useFetchTxDetail } from '../../common/hooks';
import { checkTxAddr, TxTypesMap, SellStatusMap, currencyFormat } from '../../utils/utils';
import {
  TxDetailClaim,
  TxDetailSell,
  TxDetailSeriesClaim,
  TxDetailUpdateSell,
  TxDetailHash,
  TxDetailRight,
  OriginData,
  TxDetailUserBind,
  FetchStatus,
  TxDetailKv,
} from '../../common/interface';
import './transaction.scss';

function TxDataContent(contents: { label: string; value: ReactNode; noEllipsis?: boolean }[]) {
  return (
    <Row gutter={16}>
      {contents?.map((content, index) => (
        <Col span={6} key={`__content--${content.label}_${index}`}>
          <Row>
            <Col span={3}>{content.label}</Col>
            <Col
              span={9}
              className={content.noEllipsis ? '' : 'overflow-ellipsis'}
              title={typeof content.value === 'string' ? content.value : ''}
            >
              {content.value}
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
}

function generateDetailData(
  type: string | undefined | null,
  originDataObj: OriginData | undefined,
): { label: string; value: ReactNode }[] {
  if (!type || !originDataObj) {
    return [];
  }
  const { author } = originDataObj as TxDetailClaim;
  const authorStr = Array.isArray(author) ? author.join('，') : author;
  const txDetailMap: { [key: string]: { label: string; value: ReactNode; noEllipsis?: boolean }[] } = {
    series_claim: [
      {
        label: '系列ID',
        value: <CopyText content={`${(originDataObj as TxDetailClaim).seriesId}`} ellipsis showTips />,
      },
      { label: '系列名', value: (originDataObj as TxDetailSeriesClaim).seriesName },
      { label: '发行量', value: (originDataObj as TxDetailSeriesClaim).totalCount },
      {
        label: '创作者地址',
        value: (
          <CopyText
            content={`${(originDataObj as TxDetailSeriesClaim).creatorAddr}`}
            externalLink={true}
            link={`/addrs/${(originDataObj as TxDetailSeriesClaim).creatorAddr}`}
            ellipsis
          />
        ),
      },
      {
        label: '封面地址',
        value: (
          <CopyText
            content={`${(originDataObj as TxDetailSeriesClaim).coverUrl}`}
            externalLink={true}
            link={`${(originDataObj as TxDetailSeriesClaim).coverUrl}`}
          />
        ),
      },
    ],
    nft_claim: [
      {
        label: '起始NFT ID',
        value: (
          <CopyText
            content={`${(originDataObj as TxDetailClaim).nftId}`}
            externalLink={true}
            link={`/nft/${(originDataObj as TxDetailClaim).nftId}`}
            ellipsis
            showTips
          />
        ),
        noEllipsis: true,
      },
      { label: '作品哈希', value: (originDataObj as TxDetailClaim).hash },
      {
        label: '系列ID',
        value: (originDataObj as TxDetailClaim).seriesId,
      },
      { label: '作者名', value: authorStr },
      { label: '发行量', value: (originDataObj as TxDetailClaim).count },
      { label: '作品名称', value: (originDataObj as TxDetailClaim).name },
      {
        label: '作品 URL',
        value:
          (originDataObj as TxDetailClaim).coverUrl === '********' ? (
            (originDataObj as TxDetailClaim).coverUrl
          ) : (
            <CopyText
              content={`${(originDataObj as TxDetailClaim).coverUrl}`}
              externalLink={true}
              link={`${(originDataObj as TxDetailClaim).coverUrl}`}
              ellipsis
              showTips
            />
          ),
      },
    ],
    nft_transfer: [
      {
        label: 'NFT ID',
        value: (
          <CopyText
            content={`${(originDataObj as TxDetailSell).nftId}`}
            externalLink={true}
            link={`/nft/${(originDataObj as TxDetailSell).nftId}`}
            ellipsis
            showTips
          />
        ),
      },
    ],
    nft_buy: [
      {
        label: 'NFT ID',
        value: (
          <CopyText
            content={`${(originDataObj as TxDetailSell).nftId}`}
            externalLink={true}
            link={`/nft/${(originDataObj as TxDetailSell).nftId}`}
            ellipsis
            showTips
          />
        ),
      },
    ],
    nft_buy_pay: [
      {
        label: 'NFT ID',
        value: <CopyText content={`${(originDataObj as TxDetailSell).nftId}`} ellipsis showTips />,
      },
    ],
    nft_update_sell: [
      {
        label: 'NFT ID',
        value: (
          <CopyText
            content={`${(originDataObj as TxDetailUpdateSell).nftId}`}
            externalLink={true}
            link={`/nft/${(originDataObj as TxDetailUpdateSell).nftId}`}
            ellipsis
            showTips
          />
        ),
      },
      { label: '销售状态', value: SellStatusMap.get((originDataObj as TxDetailUpdateSell).status) },
      { label: '售价', value: (originDataObj as TxDetailUpdateSell).price },
    ],
    add: [
      {
        label: '文件哈希',
        value: (
          <CopyText
            content={`${(originDataObj as TxDetailHash).evHash}`}
            link={`/transactions/${(originDataObj as TxDetailHash).evHash}`}
          />
        ),
      },
    ],
    userBindV2: [
      {
        label: '地址',
        value: (
          <CopyText
            content={`${(originDataObj as TxDetailUserBind).addr}`}
            link={`/addrs/${(originDataObj as TxDetailUserBind).addr}`}
            externalLink={true}
          />
        ),
      },
    ],
    dci_claim: [
      {
        label: '文件hash',
        value: <CopyText content={`${(originDataObj as TxDetailRight).evId}`} ellipsis showTips />,
      },
      { label: '作者名', value: authorStr },
      { label: '作品名称', value: (originDataObj as TxDetailRight).name },
      { label: '作品类型', value: (originDataObj as TxDetailRight).type },
      { label: '作品创作属性', value: (originDataObj as TxDetailRight).property },
      {
        label: '作品URL',
        value: (originDataObj as TxDetailRight).url,
      },
      {
        label: '权利人信息列表',
        value: (
          <Table
            rowKey="type"
            data={(originDataObj as TxDetailRight).rights || []}
            columns={[
              {
                colKey: 'type',
                title: '权利类型',
              },
              {
                colKey: 'persons',
                title: '权利者名称',
                render: ({ row }) => row.persons.join('，'),
              },
            ]}
          />
        ),
      },
    ],
    // kv_save_v1: [
    //   { label: 'KEY', value: (originDataObj as TxDetailKv).kvKey },
    //   { label: 'Value', value: (originDataObj as TxDetailKv).kvValue },
    // ],
  };
  return txDetailMap[type] || [];
}

export function TransactionDetailPage() {
  const { txId } = useParams<{ txId: string }>();
  const { data, fetch, status } = useFetchTxDetail();
  const [originDataToJson, setOriginDataToJson] = useState<OriginData>('');
  const [originDataToJsonString, setOriginDataToJsonString] = useState('');
  const [detailData, setDetailData] = useState<{ label: string; value: ReactNode }[]>([]);

  useEffect(() => {
    if (txId) {
      fetch({
        txId,
      });
    }
  }, [txId]);

  useEffect(() => {
    if (data?.ccmName && data.originData) {
      try {
        const json = JSON.parse(data?.originData as string);
        if (json.kvValue) {
          try {
            json.kvValueJson = JSON.stringify(JSON.parse(json.kvValue), null, 4);
          } catch (e) {}
        }
        setOriginDataToJson(json);
        setOriginDataToJsonString(JSON.stringify(json, null, 4));
        const formated = generateDetailData(data?.ccmName, json);
        setDetailData(formated);
      } catch (e) {}
    }
  }, [data]);

  const renderTo = () => {
    if (data?.ccmName === 'nft_transfer' || data?.ccmName === 'nft_buy' || data?.ccmName === 'nft_buy_pay') {
      return checkTxAddr(data?.to) ? (
        <CopyText content={`${data?.to}`} externalLink={true} link={`/addrs/${data?.to}`} />
      ) : data?.to ? (
        <CopyText content={`${data?.to}`} />
      ) : (
        <></>
      );
    }
    return <CopyText content={`${data?.ccName}`} />;
  };

  return (
    <>
      <h3 className="Detail_title">交易详情</h3>
      {(status === FetchStatus.INIT || status === FetchStatus.LOADING) && (
        <Card>
          <Card.Body>
            <Icon name="loading" />
            &nbsp;加载中
          </Card.Body>
        </Card>
      )}
      {status === FetchStatus.SUCCESS && data && (
        <>
          <DetailHeadCard
            header={
              <h3>
                交易哈希 <CopyText content={`${data?.txId}`} />
              </h3>
            }
            contents={[
              { label: '交易时间', value: data?.txTime },
              {
                label: '区块高度',
                value: <CopyText content={`${data?.height}`} externalLink={true} link={`/blocks/${data?.height}`} />,
              },
              {
                label: '从',
                value: checkTxAddr(data?.from) ? (
                  <CopyText content={`${data?.from}`} externalLink={true} link={`/addrs/${data?.from}`} />
                ) : data?.from ? (
                  <CopyText content={`${data?.from}`} />
                ) : (
                  <></>
                ),
                invisible: data?.ccmName === 'userBindV2' || data?.ccmName === 'bind',
              },
              { label: '方法', value: TxTypesMap.get(data?.ccmName) ?? '其他' },
              {
                label: '到',
                value: renderTo(),
                invisible: data?.ccmName === 'userBindV2' || data?.ccmName === 'bind',
              },
              {
                label: '合约名',
                value: data?.ccName,
                invisible: data?.ccmName !== 'userBindV2',
              },
              { label: '交易金额', value: `¥${currencyFormat(data?.amount)}` },
            ]}
          />

          <Card>
            <Card.Body>
              <h3 className="mt0">交易数据</h3>
              {data?.ccmName !== 'kv_save_v1' &&
                (detailData.length ? (
                  TxDataContent(detailData)
                ) : (
                  <div className="text-content">{originDataToJsonString || data?.originData}</div>
                ))}
              {data?.ccmName === 'kv_save_v1' && (
                <div className="kvtx_data">
                  <div className="kvtx_data_i">
                    <div className="kvtx_data_l">KEY</div>
                    <div className="kvtx_data_v">{(originDataToJson as TxDetailKv).kvKey}</div>
                  </div>
                  <div className="kvtx_data_i">
                    <div className="kvtx_data_l">Value</div>
                    <div className="kvtx_data_v kvtx_data_v_b">
                      {(originDataToJson as TxDetailKv).kvValueJson || (originDataToJson as TxDetailKv).kvValue}
                    </div>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </>
      )}
      {status === FetchStatus.FAILED && (
        <Card>
          <Card.Body>
            <Icon name="error-circle" />
            &nbsp;找不到数据
          </Card.Body>
        </Card>
      )}
    </>
  );
}
