import { Col, Row } from '@tencent/tdesign-react';
import React, { ReactNode } from 'react';
import Card from './card';

interface PropTypes {
  header?: ReactNode | ReactNode[];
  contents?: { label: string; value: ReactNode; invisible?: boolean; noEllipsis?: boolean }[];
}

export function DetailHeadCard({ header, contents }: PropTypes) {
  return (
    <>
      <Card>
        {header && <Card.Header>{header}</Card.Header>}
        <Card.Body>
          <Row gutter={16}>
            {contents?.map(
              (content, index) =>
                !content.invisible && (
                  <Col span={6} key={`__content--${content.label}_${index}`}>
                    <Row>
                      <Col span={3}>{content.label}</Col>
                      <Col span={9} className={content.noEllipsis ? '' : 'overflow-ellipsis'}>
                        {content.value}
                      </Col>
                    </Row>
                  </Col>
                ),
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
