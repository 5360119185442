import classNames from 'classnames';
import React, { ReactNode } from 'react';

import './metrics-board.scss';

export interface PropTypes {
  title: ReactNode;
  value: ReactNode;
  unit?: ReactNode;
  className?: string;
  style?: { [key: string]: string };
}

export function MetricsBoard({ title, value, unit, className, style }: PropTypes) {
  const cls = classNames('metrics-board', className);
  return (
    <div className={cls} style={style}>
      <p className="metrics-board__title">{title}</p>
      <div className="metrics-board__value-container">
        <span className="metrics-board__value">{value}</span>
        {unit && <span className="metrics-board__unit">{unit}</span>}
      </div>
    </div>
  );
}
