import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Table, Tabs, Tag, IconFont, Tooltip, Form, Select } from '@tencent/tdesign-react';
import { Icon } from 'tdesign-icons-react';
import { DetailHeadCard } from '../../common/components/detail-head-card';
import { CopyText } from '../../common/components/copy-text';
import { useParams } from 'react-router';
import { CommonTable } from '../../common/components/common-table/common-table';
import { useFetchAddrAssetList, useFetchTxList } from '../../common/hooks';
import { Context } from '../../common/reducer';
import { Link } from 'react-router-dom';
import Card from '../../common/components/card';
import { TxTypesMap, getDateSorter, currencyFormat, getQueryString, TXTYPEOPTIONS } from '../../utils/utils';
import LinkTo from '../../common/components/links/link-to';
import LinkTxId from '../../common/components/links/link-txid';
import LinkFrom from '../../common/components/links/link-from';
import LinkHeight from '../../common/components/links/link-height';
import FormItem from '@tencent/tdesign-react/es/form/FormItem';
const { TabPanel } = Tabs;

function TxRecordTable({ addr, setError }: { addr: string; setError: (isError: boolean) => void }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { data, fetch, retCode } = useFetchTxList();
  const [ccmName, setCcmName] = useState('select_all');

  const updateCcName = useCallback((value) => {
    setCcmName(value);
    setCurrentPage(1);
  }, []);
  useEffect(() => {
    if (addr) {
      const name = ccmName === 'select_all' ? '' : ccmName;
      fetch({
        addr,
        page: currentPage,
        ccmName: name,
        pageSize,
      });
    }
  }, [addr, currentPage, pageSize, ccmName]);
  useEffect(() => {
    setError(retCode !== 0);
  }, [retCode]);
  let totalContent = '';
  if (data && 'total' in data) {
    totalContent = `最近${Math.min(data.total, 20000)}条，共${data.total}条`;
  }
  return (
    <>
      <div className="table_form mt20">
        <Form layout="inline" labelWidth={60} style={{ marginLeft: 20, justifyContent: 'start' }}>
          <FormItem label="" name="ccmName">
            <Select
              defaultValue={TXTYPEOPTIONS[0].value}
              value={ccmName}
              options={TXTYPEOPTIONS}
              onChange={updateCcName}
            ></Select>
          </FormItem>
        </Form>
        <CommonTable
          rowKey="txId"
          data={data?.txList || []}
          columns={[
            {
              align: 'left',
              colKey: 'txId',
              title: '交易哈希',
              width: 265,
              ellipsis: true,
              render: ({ row }) => (
                <div className="tr-status">
                  {row.validationCode !== 0 && (
                    <Tooltip content="交易失败" placement="top" showArrow destroyOnClose>
                      <IconFont className="tr-status-icon" name="error-circle" />
                    </Tooltip>
                  )}
                  <LinkTxId txId={row.txId} />
                </div>
              ),
            },
            {
              align: 'left',
              colKey: 'tag',
              title: '交易属性',
              width: 110,
              render: ({ row }) =>
                row.from === addr ? (
                  <Tag theme="primary" size="small" className="tx-tag">
                    OUT
                  </Tag>
                ) : (
                  <Tag theme="success" variant="dark" size="small" className="tx-tag">
                    IN
                  </Tag>
                ),
            },
            {
              align: 'left',
              colKey: 'height',
              title: '区块高度',
              width: 120,
              render: ({ row }) => <LinkHeight height={row.height} />,
            },
            {
              align: 'left',
              colKey: 'from',
              title: '从',
              width: 220,
              ellipsis: true,
              render: ({ row }) => <LinkFrom from={row.from} />,
            },
            {
              align: 'left',
              colKey: 'to',
              title: '到',
              width: 220,
              ellipsis: true,
              render: ({ row }) => <LinkTo to={row.to} ccName={row.ccName} ccmName={row.ccmName} />,
            },
            {
              align: 'left',
              colKey: 'txTime',
              title: '交易时间',
              width: 200,
              ellipsis: true,
              sorter: getDateSorter('txTime'),
              sortType: 'all',
            },
            {
              align: 'left',
              colKey: 'ccmName',
              title: '方法',
              ellipsis: true,
              width: 150,
              render: ({ row }) => TxTypesMap.get(row?.ccmName) ?? '其他',
            },
            {
              align: 'left',
              colKey: 'amount',
              title: '交易金额',
              width: 180,
              render: ({ row }) => `￥${currencyFormat(row.amount)}`,
            },
          ]}
          hover
          pagination={{
            pageSize,
            current: currentPage,
            total: Math.min(data?.total || 0, 20000),
            totalContent,
            showJumper: true,
            onChange: (pageInfo) => {
              const { current, pageSize } = pageInfo;
              setCurrentPage(current);
              setPageSize(pageSize);
            },
          }}
          onSortChange={() => {}}
          loading={!data}
        />
      </div>
    </>
  );
}

function AssetsTable({ addr, setError }: { addr: string; setError: (isError: boolean) => void }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { data, fetch, retCode } = useFetchAddrAssetList();
  useEffect(() => {
    if (addr) {
      fetch({
        addr,
        page: currentPage,
        pageSize,
      });
    }
  }, [addr, pageSize, currentPage]);
  useEffect(() => {
    setError(retCode !== 0);
  }, [retCode]);
  let totalContent = '';
  if (data && 'total' in data) {
    totalContent = `最近${Math.min(data.total, 20000)}条，共${data.total}条`;
  }
  return (
    <Table
      rowKey="nftId"
      data={data?.nftList || []}
      columns={[
        {
          align: 'left',
          colKey: 'nftId',
          title: 'NFT ID',
          width: 350,
          ellipsis: true,
          render: ({ row }) => (
            <Link
              to={(location) => ({
                ...location,
                pathname: `/nft/${row.nftId}`,
              })}
              target="_blank"
            >
              {row.nftId}
            </Link>
          ),
        },
        {
          align: 'left',
          colKey: 'author',
          title: '作者名',
          width: 150,
          ellipsis: true,
        },
        {
          align: 'left',
          colKey: 'name',
          title: '作品名称',
          width: 200,
          ellipsis: true,
        },
        {
          align: 'left',
          colKey: 'getTime',
          title: '获得时间',
          width: 150,
          ellipsis: true,
        },
      ]}
      hover
      pagination={{
        pageSize,
        current: currentPage,
        total: Math.min(data?.total || 0, 20000),
        totalContent,
        showJumper: true,
        onChange: (pageInfo) => {
          const { current, pageSize } = pageInfo;
          setCurrentPage(current);
          setPageSize(pageSize);
        },
      }}
      loading={!data}
    />
  );
}

export function AddrsDetailPage() {
  const { data, fetch } = useFetchTxList();
  const { addr } = useParams<{ addr: string }>();
  useEffect(() => {
    if (addr) {
      fetch({
        addr,
      });
    }
  }, [addr]);
  const {
    state: { isError },
    dispatch,
  } = useContext(Context);
  const setError = (isError: boolean) =>
    dispatch({
      type: 'SET_ERROR',
      isError,
    });
  let tab = getQueryString('tab');
  if (tab !== 'tx' && tab !== 'asset') {
    tab = 'tx';
  }
  // const [tab, setTab] = useState<any>(initTab);

  return (
    <>
      {!isError ? (
        <>
          <h3 className="Detail_title">地址详情</h3>
          <DetailHeadCard
            header={
              <>
                <h3>
                  地址 <CopyText content={addr} />
                </h3>
              </>
            }
            contents={[
              {
                label: '交易数量',
                value: data?.total,
              },
            ]}
          />
          <div className="Addr-Tabs__container">
            <Tabs
              theme="card"
              size="medium"
              defaultValue={tab}
              onChange={(value) => {
                console.log('value', value);
                window.location.search = `?tab=${value}`;
                // window.location.search = `?tab=${tab}`;
              }}
            >
              <TabPanel label="交易记录" value="tx">
                <TxRecordTable addr={addr} setError={setError} />
              </TabPanel>
              <TabPanel label="资产列表" value="asset">
                <AssetsTable addr={addr} setError={setError} />
              </TabPanel>
            </Tabs>
          </div>
        </>
      ) : (
        <Card>
          <Card.Body>
            <Icon name="error-circle" />
            &nbsp;找不到数据
          </Card.Body>
        </Card>
      )}
    </>
  );
}
